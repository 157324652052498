<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-lg-4"
        label="Nome"
        v-model="pricing.name"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
      />

      <FormInputTextWidget
        class="col-lg-3"
        label="Ordinamento"
        :min="0"
        :step="1"
        type="number"
        v-model.number="pricing.rank"
        :errorMsg="errorHash.rank"
        @input="removeFieldError('rank')"
      />

      <div class="col-lg-5">
        <FormInputCheckboxWidget
          v-model="pricing.enabled"
          :errorMsg="errorHash.enabled"
          @input="removeFieldError('enabled')"
          not-required
        >Attivabile</FormInputCheckboxWidget>

        <FormInputCheckboxWidget
          v-if="pricing.enabled"
          v-model="pricing.listed"
          :errorMsg="errorHash.listed"
          @input="removeFieldError('listed')"
          not-required
        >Elencato pubblicamente</FormInputCheckboxWidget>

        <FormInputCheckboxWidget
          v-if="pricing.enabled && pricing.listed"
          v-model="pricing.default"
          :errorMsg="errorHash.default"
          @input="removeFieldError('default')"
          not-required
        >Predefinito</FormInputCheckboxWidget>
      </div>
    </div>

    <div class="row">
      <FormInputTextWidget
        v-if="!pricing.listed"
        class="col-lg-4"
        label="Coupon di attivazione"
        v-model="pricing.activatingCoupon"
        :errorMsg="errorHash.activatingCoupon"
        @input="removeFieldError('activatingCoupon')"
      />
    </div>

    <div class="row">
      <FormInputCurrencyWidget
        class="col-lg-6"
        label="Costo di attivazione"
        v-model="pricing.activation_price"
        :errorMsg="errorHash.activation_price"
        @input="removeFieldError('activation_price')"
      />

      <FormInputCurrencyWidget
        class="col-lg-6"
        label="Costo mensile"
        v-model="pricing.monthly_price"
        :errorMsg="errorHash.monthly_price"
        @input="removeFieldError('monthly_price')"
      />

      <FormInputCurrencyWidget
        class="col-lg-6"
        label="Fee fissa"
        v-model="pricing.fixed_fee"
        :errorMsg="errorHash.fixed_fee"
        @input="removeFieldError('fixed_fee')"
      />

      <FormInputPercentageWidget
        class="col-lg-6"
        label="Fee percentuale"
        v-model="pricing.percent_fee"
        :errorMsg="errorHash.percent_fee"
        @input="removeFieldError('percent_fee')"
      />
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <button class="btn btn-primary px-5">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate.js';

import FormContainer from '@/views/components/Form/FormContainer.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FormInputCurrencyWidget from '@/libs/Form/components/Inputs/Widgets/FormInputCurrencyWidget.vue';
import FormInputPercentageWidget from '@/libs/Form/components/Inputs/Widgets/FormInputPercentageWidget.vue';
import FormInputCheckboxWidget from '@/libs/Form/components/Inputs/Widgets/FormInputCheckboxWidget.vue';

export default {
  mixins: [validateMixin],
  components: {
    FormContainer,
    FormInputTextWidget,
    FormInputCurrencyWidget,
    FormInputPercentageWidget,
    FormInputCheckboxWidget,
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert'),
  },
  props: {
    pricing: {
      type: Object,
      default: () => ({
        name: null,
        rank: 10,
        activation_price: 0,
        monthly_price: 0,
        fixed_fee: 25,
        percent_fee: 0,
        enabled: true,
        listed: false,
        default: false,
        activatingCoupon: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      if (!this.pricing.name) {
        this.addError('Inserisci il nome', 'name');
      }

      if (!this.pricing.listed && !this.pricing.activatingCoupon) {
        this.addError('Inserisci il coupon di attivazione', 'activatingCoupon');
      }

      return !this.hasErrors;
    },
    submit () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.isValid()) {
        return;
      }

      const pricingToReturn = { ...this.pricing };
      if (!pricingToReturn.enabled) {
        pricingToReturn.default = false;
      }

      this.$emit('submitForm', pricingToReturn);
    },
  },
};

</script>
