<template>
  <FormInputText :id="inputId" type="number" :step="step" :notRequired="notRequired" :disabled="disabled" :placeholder="placeholder" v-model.number="internalValue" />
</template>

<script>

import formInputFieldMixin from './mixins/formInputField.js';
import formInputTextPropsMixin from './mixins/formInputTextProps.js';

import FormInputText from './FormInputText.vue';

export default {
  mixins: [formInputFieldMixin, formInputTextPropsMixin],
  components: {
    FormInputText,
  },
  props: {
    step: {
      type: String,
      default: '.01',
    },
  },
  computed: {
    internalValue: {
      set (val) {
        this.$emit('input', val);
      },
      get () {
        return parseFloat(this.value);
      },
    },
  },
};

</script>
